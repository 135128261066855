/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import jBox from 'jBox';
// import 'moment';
// require momentjs/locale/nb.js
// require momentjs/locale/en-gb.js
// import jQuery from 'jquery';
// window.jQuery = jQuery;
// window.$ = jQuery;
// window.jquery = jQuery;

// import 'turbolinks';

import Rails from '@rails/ujs';
import SweetAlert2Confirm from 'sweet-alert-confirm';
Rails.delegate(document, 'a[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'button[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)

// import 'jquery-ujs';

// require show_date
// require select2-selectors


// import 'bootstrap'
// required by bootstrap and mdb
import 'tether'
import 'popper.js'

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/popover';

// import 'sweetalert2';
import 'select2';

// Internal imports
// import 'sweet-alert-confirm';

// TODO if needed:
// require clipboard
// require clipper
// require_tree .
// require froala
// require froala_editable
// require pnotify/pnotify
// require pnotify/pnotify.buttons
// require pnotify_changes
// require remote_form
// require remote-delete
// require tmpl

// function application_setup() {
// }
// document.addEventListener('DOMContentLoaded', application_setup);

Rails.start();

